<template>
  <div id="quality-doctor" v-loading="dataListLoading">
    <!--    <nav-header/>-->
    <div class="uf uf-ac fwrap-w" style="background-color: #FFFFFF">
      <div class="quality_doctor_banner">
        <div class="banner-title">一站式服务</div>
        <div class="banner-desc">针对服务需求不明确的平台企业，研究政策方针，汇聚行业数据，分析市场动态，挖掘企业需求，
          利用现有检测、认证、技术服务等资源，为平台企业定制个性化一站式服务方案。
        </div>
      </div>
      <div class="doctor-title-wrapper wi100 mt-1">
        <div>
          <el-tabs v-model="activeIndex" type="card" @tab-click="handleTabClick">
            <el-tab-pane v-for="(item) in serviceList" :key="item.dictId" :label="item.dictName" :name="item.dictId">
              <div slot="label">
                <div>{{ item.dictName }}</div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div v-loading="dataListLoading" v-if="doctorList.length > 0" class="main-content-doctor">
        <div class="item-wrapper">
          <doctor-card v-for="item in doctorList" @goConsult="goConsult(item.sysUserEntity, item.doctorDesc)"
                       :key="item.doctorId" :doctor="item" :scopeDict="industryScopeDict"></doctor-card>
        </div>
        <div class="mt-1 tac">
          <el-pagination
              background
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageIndex"
              :page-sizes="[6,15,20,24]"
              :page-size="pageSize"
              :total="totalCount"
              layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </div>
      <div class="empty-wrapper tac" v-else>
        <img src="../../assets/img/zwsj.jpg"/>
      </div>
      <chat-box v-if="chatBoxVisible" ref="chatBoxRef" @closeCallBack="closeCallBack"/>
      <intelligence-chat v-if="intelligenceVisible" ref="intelligenceRef" @closeCallBack="closeCallBack"/>
    </div>
  </div>
</template>

<script>
import DoctorCard from './components/doctor-card'
import ChatBox from '../components/chat-box'
import IntelligenceChat from '../components/intelligence-chat'

export default {
  name: 'quality-doctor',
  components: {
    ChatBox,
    IntelligenceChat,
    DoctorCard
  },
  data() {
    return {
      icons: [
        'jianyan',
        'biaozhun',
        'zl',
        'chanpin',
        'xinyong',
        'pingjia',
        'zhiliang',
        'zhichan'
      ],
      defaultAvatar: require('../../assets/img/kefu.jpg'),
      doctorList: [],
      pageIndex: 1,
      pageSize: 24,
      totalCount: 0,
      industryScope: '8',
      industryScopeDict: {},
      chatBoxVisible: false,
      intelligenceVisible: false,
      messageNumber: 0,
      timeNumber: 0,
      dataListLoading: false,
      activeIndex: '8',
      serviceItemList: []
    }
  },
  computed: {
    serviceList() {
      let dict = this.industryScopeDict
      let list = []
      for (let key in dict) {
        list.push({dictId: key, dictName: dict[key]})
      }
      return list.reverse()
    }
  },
  created() {
    this.$getDictMap('INDUSTRY_SCOPE', (dict) => {
      this.industryScopeDict = dict['INDUSTRY_SCOPE']
    })
    this.getDoctorList()
    // 查询所有质量医生
    // this.listAllDoctor()
    // this.getServiceItemMenu()
    this.reServiceList('8','8')
  },
  methods: {
    handleTabClick(tab) {
      this.activeIndex = tab.name
      this.reServiceList(tab.name, tab.name)
    },
    // 人工诊断
    goConsult(doctor, doctorDesc) {
      this.chatBoxVisible = true
      this.$nextTick(() => {
        this.$refs.chatBoxRef.init(doctor ? doctor : {}, doctorDesc, false)
      })
    },
    // 获取总的--未读消息数量
    getMessageNumber() {
      this.$http({
        url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.MESSAGE_NUMBER),
        method: 'GET',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.messageNumber = data.count || 0
        }
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDoctorList(true)
      // this.listAllDoctor()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDoctorList(false)
      // this.listAllDoctor()
    },
    reServiceList(industryScope, index) {
      this.industryScope = industryScope
      this.getDoctorList(true)
      this.activeIndex = index
    },
    //  获取质量医生列表
    getDoctorList(isPage = false) {
      if (isPage) {
        this.pageIndex = 1
      }
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.DOCTOR_LIST),
        method: 'GET',
        params: this.$http.adornParams({
          page: this.pageIndex,
          limit: this.pageSize,
          industryScope: this.industryScope
        })
      }).then(({data}) => {
        this.dataListLoading = false
        if (data && data.code === 0) {
          let page = data.page || {}
          this.doctorList = page.list || []
          this.totalCount = page.totalCount || 0
        }
      })
    },
    closeCallBack(isRefresh = false) {
      this.chatBoxVisible = false
      this.intelligenceVisible = false
      if (isRefresh) {
        this.getMessageNumber()
        this.timeNumber = setInterval(() => {
          this.getMessageNumber()
        }, 5000)
      }
    },
    listAllDoctor () {
      this.dataListLoading = true
      this.$http({
        url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.DOCTOR_LIST),
        method: 'GET',
        params: this.$http.adornParams({
          page: this.pageIndex,
          limit: this.pageSize
        })
      }).then(({data}) => {
        if (data && data.code == 0) {
          let page = data.page || {}
          this.doctorList = page.list || []
          this.totalCount = page.totalCount || 0
        }
        this.dataListLoading = false
      })
    },
    //控制字数
    ellipsis(value, length) {
      if (!value) return ''
      if (value.length > length) {
        return value.slice(0, length) + '...'
      }
      return value
    }
  }
}
</script>

<style scoped>
.quality_doctor_banner {
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: flex-start;*/
  /*flex-direction: column;*/
  width: 100%;
  height: 220px;
  /*background-color: #4647BB;*/
  background-image: url("../../../src/assets/img/quality-doctor/quality-doctor-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  /*background-position: 90% 50%;*/
  padding: 50px 10%;
}

.banner-title {
  font-size: 36px;
  font-weight: 800;
  color: #FFFFFF
}

.quality_doctor_banner .banner-desc {
  text-indent: 2.2rem;
  max-width: 50%;
  margin-top: 10px;
  font-size: 1.1rem;
  line-height: 2rem;
  color: #FFFFFF;
}

.doctor-title-wrapper {
  display: flex;
  justify-content: center;
  justify-items: center;
  /*min-height: 600px;*/
  width: 100%;
  /*height: 60px;*/
  /*background-color: #FFFFFF;*/
  /*background: transparent;*/
}

#quality-doctor .main-content-doctor {
  width: 100%;
  display: flex;
  justify-content: center;
  /*align-items: center;*/
  justify-items: center;
  flex-direction: column;
  margin: 0 5%;
  /*height: 520px;*/
  /*background-color: #FFFFFF;*/
  animation: fadein 500ms linear;
  padding: 10px 5%;
}

.active {
  border: 1px solid;
  color: white;
  background-color: #409EFF;
}


.iconfont {
  font-size: 80px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.iconfont:hover {
  animation: ring 500ms linear;
}

.doctor-avatar-wrap {
  width: 100%;
  display: block;
}

.doctor-content {
  margin-top: .6rem;
  line-height: 1.5rem;
}

.item-label {
  font-weight: bold;
  display: -moz-inline-box;
  display: inline-block;
  width: 80px;
  text-align: right;
}

.a-fadeinL {
  -webkit-animation-name: fadeinL;
  -moz-animation-name: fadeinL;
  -ms-animation-name: fadeinL;
  animation-name: fadeinL;
}

.doctor-card {
  animation: fadeinL 1s linear;
}

/* 淡入-从左 */
@-webkit-keyframes fadeinL {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeinL {
  0% {
    opacity: 0;
    -moz-transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-ms-keyframes fadeinL {
  0% {
    opacity: 0;
    -ms-transform: translateY(100px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}

@keyframes fadeinL {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes circle {
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
}

@keyframes ring {
  0% {
    transform: scale(1);
  }
  10%, 20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

.empty-wrapper {
  width: 100%;
  height: 520px;
}

/* 淡出-向上 */
@-webkit-keyframes fadeoutT {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100px);
  }
}

@-moz-keyframes fadeoutT {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-100px);
  }
}

@-ms-keyframes fadeoutT {
  0% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -ms-transform: translateY(-100px);
  }
}

@keyframes fadeoutT {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}

/* 淡入 */
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.icon-svg {
  font-size: 1.6rem;
  margin-top: 15px;
  /*margin-bottom: 15px;*/
}

#quality-doctor .item-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /*width: 95%;*/
  /*height: 195px;*/
}
</style>
