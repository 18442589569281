<template>
  <div class="doctor-content">
    <div class="img-wrapper">
      <el-image :src="sysInfo.headPath" fit="cover">
        <div slot="error">
          <div  class="error-img"></div>
        </div>
      </el-image>
    </div>
    <div class="doctor-content-center">
      <div>
        <div class="mb-1 title-wrapper">
          <span class="fwb">{{doctor.userName}}</span>
          <span class="f12" style="float: right"><span style="color: #606266"></span><el-tag size="small" type="success">{{scopeDict[doctor.industryScope]}}</el-tag></span>
        </div>
        <div class="custom-divider"></div>
        <div>
          <span class="f14" style="color: #409EFF">
             {{doctor.company}}
          </span>
        </div>
        <div  class="doctor-desc" :title="doctor.doctorDesc">
          <span style="color: #409EFF;">专家简介：</span>
          <span v-if="doctor.doctorDesc" style="color: #606266">{{doctor.doctorDesc && doctor.doctorDesc.length > 58 ? doctor.doctorDesc.substr(0,56) + '...' : doctor.doctorDesc}}</span>
          <span v-else>相关介绍正在完善中。</span>
          <div class="mt-1">
            <span class="f14" style="color: #67C23A">状态：</span><el-tag :type="doctor.onLine ? 'success' : 'info' " size="mini">
            {{doctor.onLine ? '在线' : '离线'}}
          </el-tag>
          </div>
        </div>
        <div class="footer-wrapper tar">
          <el-button type="primary" :disabled="!doctor.onLine" @click="goConsult(sysInfo, doctor.doctorDesc)" size="mini" icon="el-icon-chat-dot-square">在线咨询</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'doctor-card',
  props: {
    doctor: {
      type: Object,
      default: () => {
        return {}
      }
    },
    scopeDict: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    sysInfo () {
      return this.doctor.sysUserEntity || {}
    }
  },
  methods: {
    goConsult() {
      this.$emit('goConsult')
    },
  }
}
</script>

<style scoped>
  .doctor-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 450px;
    height: 200px;
    background-color: #FFFFFF;
    color: #3a3a3a;
    padding: 8px;
    margin: 8px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 8%);
  }
  .doctor-content-center {
    width: 470px;
    padding: 8px;
  }
  .img-wrapper {
    position: relative;
    top: 1px;
    left: 1px;
    width: 130px;
    height: 195px;
  }
  .error-img {
    width: 130px;
    height: 120px;
    background-image: url("../../../assets/img/kefu.jpg");
    background-size: cover;
  }
  .title-wrapper{

  }
  .doctor-desc {
    /*text-indent: 28px;*/
    line-height: 1.3rem;
    width: 100%;
    min-height: 70px;
    font-size: 14px;
  }
  .footer-wrapper {
    float: bottom;
    margin: 10px auto;
    height: 35px;
  }
  .custom-divider {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin: 2px 0px;
  }
</style>
